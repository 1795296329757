import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

// 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push (location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}


const routes = [ {
    path: "/", //首页
    name: "Index",
    component: () => import("../view/Index/Index.vue")
},{
    path: "/Demo", //首页
    name: "Demo",
    component: () => import("../view/Index/Demo.vue")
},{
    path: "/login", //首页
    name: "Login",
    component: () => import("../view/Login/login.vue")
}]

const router = new Router({
    routes
})

export default router
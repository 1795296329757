import Vue from 'vue'
import App from './App.vue'
import router from './router'
import md5 from 'js-md5'
Vue.config.productionTip = false;
Vue.prototype.$md5 = md5;
// Vue.prototype.$url = "http://local.nongji.com/api";
Vue.prototype.$url = "https://cloud.ksbigdata.com/api";

import 'qweather-icons/font/qweather-icons.css'
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import "./utils/flexible.js";
export default {
  name: 'App',
}
</script>

<style lang="scss" scoped>
  @import url('./style/base.css');
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::-webkit-scrollbar {
    width: 0 !important;height: 0;
  }
</style>>
